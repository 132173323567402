import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import { LandingPage } from './components/Home';
import "./styles.css";
import CustomerExp from './components/CustomerExp';
import { useEffect } from 'react';
function App() {
   useEffect(() => {
     window.plugSDK.init({
       app_id: "don:core:dvrv-in-1:devo/2aaTX9u511:plug_setting/1",
     });
   }, []);
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/customer" element={<CustomerExp />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
