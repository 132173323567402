import React, { useEffect, useRef, useState } from "react";
import "../styles.css";
import { AiFillThunderbolt } from "react-icons/ai";
import { GoClock } from "react-icons/go";
import qk from "../assets/qk.png";
import devrev from "../assets/devrev.png";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import aiTicketDeflectionVideo from "../assets/AITicketDelfection.mp4";
import voiceOfCustomer from "../assets/VoiceofCustomer.mp4";

const CustomerExp = () => {
  const [videoOne, setVideoOne] = useState(false);
  const [videoTwo, setVideoTwo] = useState(false);
  const videoRef = useRef(null);
  const videoTwoRef = useRef(null);

  useEffect(() => {
    if (videoOne && videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error("Autoplay was prevented:", error);
      });
    }
  }, [videoOne]);
  useEffect(() => {
    if (videoTwo && videoTwoRef.current) {
      videoTwoRef.current.play().catch((error) => {
        console.error("Autoplay was prevented:", error);
      });
    }
  }, [videoTwo]);

  return (
    <div className=" w-full herobg">
      <div className="flex flex-col text-white justify-center items-center">
        <h1 className="text-5xl font-bold mt-[2.5rem]">
          Customer Experience Command Center
        </h1>
        <h3 className="text-2xl my-3">
          Empowering your business with AI solutions
        </h3>
        <h2 className="flex text-4xl font-bold my-5 items-center gap-4">
          <AiFillThunderbolt /> Active Use Cases
        </h2>
        <div className="flex mt-6 justify-center items-center gap-x-32">
          <div className="flex bg-black flex-col gap-6 h-64 w-64 items-center border-t-2 border-l-2 border-r-2 border-b-4 rounded-3xl shadow-md shadow-white border-white">
            <h1 className="font-bold text-2xl p-2">
              Voice Of Customer Analysis
            </h1>
            <p className="px-4 text-start">
              Discover what customers think about our products and services
              across all channels
            </p>
            <button
              className="bg-blue-600 rounded-full w-32"
              onClick={() => setVideoOne(true)}
            >
              Explore
            </button>
            <Popup
              open={videoOne}
              onClose={() => setVideoOne(false)}
              modal
              closeOnDocumentClick
            >
              <div className="flex flex-col justify-center  items-center">
                <div className="popup bg-black text-white flex justify-center items-center relative p-5">
                  <button
                    className="absolute top-2 right-2 text-white bg-black bg-opacity-50 rounded-full w-8 h-8 flex items-center justify-center"
                    onClick={() => setVideoOne(false)}
                  >
                    X
                  </button>
                  <video
                    ref={videoRef}
                    src={voiceOfCustomer}
                    autoPlay
                    controls
                    className="w-[90vw] h-[50vh] rounded-lg"
                  />
                </div>
                <a
                  href="https://form.jotform.com/vasuchhirolya97/VOC_Analysis"
                  target="_blank"
                >
                  <button className="bg-blue-600 hover:bg-blue-800 text-white mt-2 rounded-full w-32">
                    Sign Up
                  </button>
                </a>
              </div>
            </Popup>
          </div>
          <div className="flex bg-black flex-col gap-6 h-64 w-64 items-center border-t-2 border-l-2 border-r-2 border-b-4 rounded-3xl shadow-md shadow-white border-white">
            <h1 className="font-bold text-2xl p-2">
              AI Ticket <br /> Deflection
            </h1>
            <p className="px-4 text-start">
              Provide instant answers with AI chatbots to maximize customer
              service efficiency
            </p>
            <button
              className="bg-blue-600  rounded-full w-32"
              onClick={() => setVideoTwo(true)}
            >
              Explore
            </button>
            <Popup
              open={videoTwo}
              onClose={() => setVideoTwo(false)}
              modal
              closeOnDocumentClick
            >
              <div className="flex flex-col justify-center  items-center">
                <div className="popup bg-black text-white flex justify-center items-center relative p-5">
                  <button
                    className="absolute top-2 right-2 text-white bg-black bg-opacity-50 rounded-full w-8 h-8 flex items-center justify-center"
                    onClick={() => setVideoTwo(false)}
                  >
                    X
                  </button>
                  <video
                    ref={videoTwoRef}
                    src={aiTicketDeflectionVideo}
                    autoPlay
                    // playsInline
                    controls
                    className="w-[90vw] h-[50vh] rounded-lg"
                  />
                </div>
                <a
                  href="https://form.jotform.com/242894063915464"
                  target="_blank"
                >
                  <button className="bg-blue-600 hover:bg-blue-800 text-white mt-2 rounded-full w-32">
                    Sign Up
                  </button>
                </a>
              </div>
            </Popup>
          </div>
        </div>
        <h2 className="flex text-4xl font-bold my-10 items-center gap-4">
          <GoClock /> Coming Soon...
        </h2>
        <div className="flex mt-6 justify-center items-center gap-x-32">
          <div className="flex bg-black flex-col gap-6 h-64 w-64 items-center border-t-2 border-l-2 border-r-2 border-b-4 rounded-3xl shadow-md shadow-white border-white">
            <h1 className="font-bold text-2xl p-2">
              Competitor <br /> Analysis
            </h1>
            <p className="px-4 text-start">
              AI-powered competitor insights for strategic advantage with Turing
              AI
            </p>
            <button className="bg-blue-600 rounded-full w-32">Loading..</button>
          </div>
          <div className="flex bg-black flex-col gap-6 h-64 w-64 items-center border-t-2 border-l-2 border-r-2 border-b-4 rounded-3xl shadow-md shadow-white border-white">
            <h1 className="font-bold text-2xl p-2">
              Smart Code <br /> Review
            </h1>
            <p className="px-4 text-start">
              Streamline reviews with AI. <br /> Find hidden issues. <br />{" "}
              Improve Code
            </p>
            <button className="bg-blue-600 rounded-full w-32">Loading..</button>
          </div>
          <div className="flex bg-black flex-col gap-6 h-64 w-64 items-center border-t-2 border-l-2 border-r-2 border-b-4 rounded-3xl shadow-md shadow-white border-white">
            <h1 className="font-bold text-2xl p-2">
              Sentimental <br /> Analysis
            </h1>
            <p className="px-4 text-start">
              AI-powered insights into customer sentiment across all channels
            </p>
            <button className="bg-blue-600 rounded-full w-32">Loading..</button>
          </div>
        </div>
        <div className="flex w-full px-6 justify-center items-center">
          <div className="flex flex-col justify-center items-center gap-2">
            <img src={qk} className=" mt-16" alt="quality kiosk logo" />
            <p>Powered By</p>
            <div className="w-32 h-24">
              <img src={devrev} alt="devrev" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerExp;
