import React, { useRef } from "react";
import QKLogoNbg1 from "../assets/qk-logo-nbg-1.png";
import illustration from "../assets/illustration.svg";
import image21 from "../assets/image-21.png";
import image22 from "../assets/image-22.png";
import image23 from "../assets/image-23.png";
import image24 from "../assets/image-24.png";
import image27 from "../assets/image-27.png";
import image28 from "../assets/image-28.png";
import image29 from "../assets/image-29.png";
import image30 from "../assets/image-30.png";
import image31 from "../assets/image-31.png";
import image32 from "../assets/image-32.png";
import image11 from "../assets/image-11.png";
import icons8ConversionRate661 from "../assets/icons8-conversion-rate-66-1.png";
import icons8Fix100 from "../assets/icons8-fix-100.png";
import icons8LowCost100 from "../assets/icons8-low-cost-100.png";
import icons8Time100 from "../assets/icons8-time-100.png";
import { useNavigate } from "react-router-dom";
import qk from "../assets/qk.png";
import devrev from "../assets/devrev.png";

const Button = ({ className, children, onClick }) => (
  <button
    className={`px-4 py-2 rounded-md transition duration-300 ${className}`}
    onClick={onClick}
  >
    {children}
  </button>
);

const StatisticCard = ({ icon, percentage, description }) => (
  <div className="flex items-center space-x-4">
    <img src={icon} alt="Icon" className="w-16 h-16 object-contain" />
    <div>
      <h3 className="text-blue-900 font-bold text-2xl">{percentage}</h3>
      <p className="font-semibold text-gray-700">{description}</p>
    </div>
  </div>
);

export const LandingPage = () => {
  const navigate = useNavigate();
  const devRevPlaygroundRef = useRef(null);

  const scrollToDevRevPlayground = () => {
    devRevPlaygroundRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const navItems = ["What we do", "Industries", "Resources", "About Us"];

  return (
    <div className="bg-white flex flex-col items-center w-full">
      <header className="bg-neutralsilver w-full px-12 py-6 flex justify-between items-center bg-gray-300 bg-blur-lg bg-opacity-90 shadow-md fixed top-0 z-50">
        <img src={QKLogoNbg1} alt="QK Logo" className="w-40" />
        <nav className="flex space-x-6">
          {navItems.map((item) => (
            <a
              key={item}
              href="#"
              className="text-gray-700 hover:text-blue-900 transition duration-300"
            >
              {item}
            </a>
          ))}
        </nav>
        {/* <div className="flex space-x-4">
          <Button className="hover:bg-gray-300">Login</Button>
          <Button className="bg-[#f48220] text-white hover:bg-[#e67616]">
            Sign up
          </Button>
        </div> */}
      </header>

      <main className="bg-white w-full flex flex-col items-center mt-20">
        <section className="flex gap-8 items-center justify-between max-w-7xl mx-auto py-20 px-4">
          <div className="flex flex-col gap-6 items-start max-w-2xl">
            <h1 className="text-5xl text-start font-bold leading-tight">
              <span className="text-blue-900">Elevate</span> <br />
              <span className="text-[#f48220]">Customer Experience</span> <br />
              <span className="text-blue-900">Boost</span>{" "}
              <span className="text-[#f48220]">Productivity</span>
            </h1>
            <p className="text-xl text-gray-700">
              Where reliable software meets seamless customer satisfaction
            </p>
            <Button
              className="bg-black text-white hover:bg-gray-800"
              onClick={scrollToDevRevPlayground}
            >
              Explore Now
            </Button>
          </div>
          <img
            src={illustration}
            alt="Illustration"
            className="max-w-md w-full object-contain"
          />
        </section>

        <section className="text-center py-16 bg-gray-50 w-full">
          <h2 className="text-4xl font-bold text-blue-900 mb-4">
            QK Center Of Excellence
          </h2>
          <p className="text-xl text-gray-700 mb-12">
            Driving Quality and Efficiency with AI
          </p>
          <div className="flex justify-center space-x-8 max-w-6xl mx-auto">
            {[
              {
                title: "Scalability",
                description:
                  "Our infrastructure and processes effortlessly adapt to increasing demands.",
              },
              {
                title: "Velocity",
                description:
                  "Our advanced monitoring tools offer unparalleled visibility into system behavior.",
              },
              {
                title: "Observability",
                description:
                  "Our agile methodologies empower teams to deliver high-quality products.",
              },
            ].map((item, index) => (
              <div
                key={index}
                className="bg-white p-6 rounded-lg shadow-lg w-1/3"
              >
                <h3 className="font-bold text-xl mb-3 text-blue-900">
                  {item.title}
                </h3>
                <p className="text-gray-700">{item.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="flex gap-16 items-center max-w-6xl mx-auto py-20">
          <img
            src={image11}
            alt="Support 360"
            className="w-1/2 object-contain"
          />
          <div className="flex flex-col gap-6 w-1/2">
            <h2 className="text-3xl font-bold text-blue-900">
              QK & DevRev Partnership
            </h2>
            <p className="text-gray-700 leading-relaxed">
              By combining QualityKiosk's expertise in digital quality assurance
              with DevRev's innovative developer experience platform, we offer a
              unified approach to building, testing, and optimizing high-quality
              software. Together, we empower teams to accelerate development
              cycles, improve product quality, and deliver exceptional customer
              experiences.
            </p>
            <Button className="bg-black text-white hover:bg-gray-800 w-40">
              Learn More
            </Button>
          </div>
        </section>

        <section className="text-center py-16 bg-gray-50 w-full">
          <h2 className="text-4xl font-bold text-blue-900 mb-12">
            Our Clients
          </h2>
          <div className="flex flex-wrap justify-center items-center gap-12 max-w-6xl mx-auto">
            {[
              image21,
              image22,
              image23,
              image24,
              image27,
              image28,
              image29,
            ].map((img, index) => (
              <img
                key={index}
                src={img}
                alt={`Client ${index + 1}`}
                className="w-32 h-32 object-contain"
              />
            ))}
          </div>
        </section>

        <section className="bg-gray-100 py-20 w-full">
          <div className="flex max-w-6xl mx-auto items-center">
            <div className="w-1/2 pr-12">
              <h2 className="text-4xl font-bold text-blue-900 mb-4">
                From Planning to Performance: <br />
                Our KPIs <span className="text-[#f48220]">Drive Success</span>
              </h2>
              <p className="text-xl text-gray-700">
                We reached here with our hard work and dedication
              </p>
            </div>
            <div className="w-1/2 grid grid-cols-2 gap-8">
              <StatisticCard
                icon={icons8Time100}
                percentage="90%"
                description="Lower Ticket Resolution Time"
              />
              <StatisticCard
                icon={icons8Fix100}
                percentage="3X"
                description="Faster Troubleshooting"
              />
              <StatisticCard
                icon={icons8LowCost100}
                percentage="40%"
                description="Lower Cost To Serve"
              />
              <StatisticCard
                icon={icons8ConversionRate661}
                percentage="40%"
                description="Higher Conversion Rate"
              />
            </div>
          </div>
        </section>

        <section
          ref={devRevPlaygroundRef}
          className="text-center py-20 max-w-6xl mx-auto"
        >
          <h2 className="text-4xl font-bold text-blue-900 mb-4">
            DevRev Playground
          </h2>
          <p className="text-xl text-gray-700 mb-12">
            Explore the Future of Software Development
          </p>
          <div className="flex justify-between space-x-8">
            {[
              {
                image: image30,
                title: "Experience Centre",
                buttonText: "Explore Now",
                navi: "/customer",
              },
              {
                image: image31,
                title: "Product 360",
                buttonText: "Coming Soon...",
                navi: "/",
              },
              {
                image: image32,
                title: "Support 360",
                buttonText: "Coming Soon...",
                navi: "/",
              },
            ].map((item, index) => (
              <div
                key={index}
                className="bg-gray-50 p-6 rounded-lg shadow-lg w-1/3 flex flex-col"
              >
                <img
                  src={item.image}
                  alt={item.title}
                  className="w-full h-48 object-contain mb-4"
                />
                <h3 className="text-xl font-semibold text-blue-900 mb-4">
                  {item.title}
                </h3>
                <button
                  className="px-4 py-2 rounded-md transition duration-300 bg-black text-white hover:bg-gray-800 mt-auto"
                  onClick={() => navigate(item.navi)}
                >
                  {item.buttonText}
                </button>
              </div>
            ))}
          </div>
        </section>
      </main>

      <footer className="bg-neutralsilver overflow-hidden text-center w-full">
        <h2 className="text-4xl font-bold text-blue-900 mb-8">Book a Demo</h2>
        <a href="https://form.jotform.com/242903625308455">
          <Button className="bg-black text-white hover:bg-gray-800 text-lg">
            Connect Now
          </Button>
        </a>
        <div className="mt-8">
          <div className="flex bg-black w-full px-6 justify-center items-center">
            <div className="flex flex-col justify-center items-center gap-2">
              <img src={qk} className=" mt-16" alt="quality kiosk logo" />
              <p className="text-white">Powered By</p>
              <div className="w-32 h-24">
                <img src={devrev} alt="devrev" />
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
